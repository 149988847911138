import React, {Component} from 'react';
import { Display3, Form, Button, Alert } from 'bootstrap-4-react';
import axios from 'axios';

class Contact extends Component{
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    state = {
        isLoading : false,
        isSent : false,
        isError : false,
    }

    /**
     * Quand le bouton "Envoyer" est cliqué
     */
    handleSubmit = (e) =>  {
        e.preventDefault();
        const nom = e.target.nom.value;
        const prenom = e.target.prenom.value;
        const email = e.target.email.value;
        const message = e.target.message.value;

        this.sendMail(nom, prenom, email, message);
        // document.getElementById("form-contact").reset();
    }

    /**
     * Appel Backend pour l'envoi du mail
     */
    sendMail = async (nom, prenom, email, message) => {
        console.log("SENDMAIL");
        this.setState({ isLoading : true, isSent : false, isError: false });
        // await axios.post('http://localhost:3001/email', {
        await axios.post('https://pc-aid.herokuapp.com/email', {
            nom : nom,
            prenom : prenom,
            email : email,
            message : message
        })
            .then((response) => {
                console.log(response);
                this.setState({ isLoading : false });
                if(response.status === 200){
                    this.setState({ isSent : true });
                    document.getElementById("form-contact").reset();
                }
                else {
                    this.setState({ isError : true, isLoading: false });
                    console.log("erreur");
                }
            }).catch(error => {
                this.setState({ isError : true, isLoading: false, isSent : false });
                console.error(error);
            });
    }

    render () {
        const { isLoading, isSent, isError } = this.state;
        return (
            <div id="contact" className="contact" >

                <div className="content-contact container" >
                    
                    <Display3 className="titre" >Contact</Display3> 
                    <Form id='form-contact' onSubmit={this.handleSubmit} className="form-contact">
                        <Form.Group >
                            <div className='row'>
                                <div className="col" >
                                    <label htmlFor="nomContact">Nom</label>
                                    <Form.Input name="nom"  id="nomContact" />
                                </div>
                                <div className="col" >
                                    <label htmlFor="prenomContact">Prénom</label>
                                    <Form.Input name="prenom" id="prenomContact" />
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className='row'>
                                <div className="col-md-6" >
                                    <label htmlFor="mailContact">Mail <span className="text-danger">*</span></label>
                                    <Form.Input required name="email" type="email" id="mailContact" />
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <label htmlFor="messageContact">Message</label>
                            <Form.Textarea required name="message" rows="7" type="password" id="sujetContact" />
                        </Form.Group>
                        { 
                            isSent ? 
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>Message envoyé !</strong>
                                </div> : 
                                <></> 
                        }
                        { isError ? <Alert danger><strong>Attention !</strong> Un problème a été rencontré lors de l'envoi du mail. Vérifiez votre adresse mail.</Alert> : <></> }

                        {
                            isLoading ?
                                <div id="bouton-chargement">
                                    <Button primary float="right" className="bouton-envoyer" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    </Button>
                                </div>
                                :
                                <div>
                                    <Button primary type="submit" float="right" className="bouton-envoyer" >Envoyer</Button>
                                </div>
                        }
                    </Form>
                </div>
            </div>
        );
    }
}

export default Contact;