import React, {Component} from 'react';
import Menu from './Menu';
import '../App.css';

class General extends Component{
  render () {
      return (
        <div>
          <Menu/>
        </div>
      );
  }
}

export default General;
