import React from 'react';
import General from './components/General';
// import '';

function App() {
  return (
    <div>
      <General/>
    </div>
  );
}

export default App;
