import React, {Component} from 'react';
import Accueil from './Accueil';
import Apropos from './Apropos';
import Contact from './Contact';
import Footer from './Footer';

import { Navbar, Collapse, Nav } from 'bootstrap-4-react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";

class Menu extends Component {
    render() {
        return (
            <div id="accueil">
                <Router>
                    <Navbar className="shadow-sm p-3 bg-white" sticky="top" expand="lg" light >
                        <Navbar>
                            <NavLink smooth to="#accueil" >
                                <img src="../logo-svg.svg" alt="PC-AID Logo" height="70" />
                            </NavLink>
                        </Navbar>
                        <Navbar.Toggler target="#navbarSupportedContent" />
                        <Collapse navbar id="navbarSupportedContent">
                            <Navbar.Nav ml="auto" >
                                <Nav.Item><NavLink smooth to="#apropos" activeClassName="selected">À Propos</NavLink></Nav.Item>
                                <Nav.Item><NavLink smooth to="#contact" activeClassName="selected">Contact</NavLink></Nav.Item>
                            </Navbar.Nav>
                        </Collapse>
                    </Navbar>
                </Router>
                <Accueil/>
                <Apropos/>
                <Contact/>
                <Footer/>
            </div>
        );
    }
}
  
export default Menu;