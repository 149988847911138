import React, {Component} from 'react';
// import ScrollAnimation from 'react-animate-on-scroll';
import ScrollableAnchor from 'react-scrollable-anchor';

class Apropos extends Component{
    render () {
        return (
            <div id="apropos" className="a-propos" >
                <div className="content-apropos container">
                    <div className="row">
                        <p className="col" >
                            <span>Depuis 2006,</span><br/>
                            PC-AID apporte une solution de gestion 
                            de l'informatique des professionnels et des particuliers.
                        </p>

                            <ScrollableAnchor id={'animateIn'}>
                                <div className="col">
                                    <img alt="img1" className="mx-auto animate__animated animate__fadeInRight" id="img1-a-propos" src="../images/img1-burst.jpg" />
                                    {/* <img alt="img1" className="mx-auto" id="img1-a-propos" src="../images/pc-img2.jpg" /> */}
                                </div>
                            </ScrollableAnchor>

                    </div>

                    <div className="row" >
                        <div className="col" >
                            <img alt="img2" className="mx-auto animate__animated animate__fadeInLeft" id="img2-a-propos" src='../images/img2-burst.jpg' />
                        </div>
                        <p className="col" id="col2">
                            <span>Réactif et disponible,</span><br/>
                            PC-AID se déplace sur site ou à domicile pour des
                            conseils et assistance efficaces.
                        </p>
                    </div>

                </div>
            </div>
        );
    }
}

export default Apropos;