import "animate.css/animate.min.css";
import React, {Component} from 'react';
import { Blockquote, Display1, Display3 } from 'bootstrap-4-react';

class Accueil extends Component{
    render () {
        return (
            <div className="accueil" >
                <div className="container" >
                        <Blockquote className="titre" >
                            <Display1 className="animate__animated animate__fadeInDown" id="titre1">
                                Assistance
                            </Display1>
                            <Display3 className="animate__animated animate__fadeInDown" id="titre2">
                                informatique
                            </Display3>
                            <Blockquote className="animate__animated animate__fadeInDown animate__delay-1s" id="titre3">
                                En Île-de-France
                            </Blockquote>
                        </Blockquote>
                </div>
            </div>
        );
    }
}

export default Accueil;