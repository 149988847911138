import React, {Component} from 'react';

class Footer extends Component{
    render () {
        return (
            <footer className="footer" >
                <div className="row">
                    <div className="col adresse">
                        <h2>Adresse</h2>
                        <p>20 avenue Saint-Saëns<br/>
                        91 600, Savigny-sur-Orge</p>
                        <p>
                            <strong>Siret</strong> : 488 477 670 00019 - APE : 9511Z
                        </p>
                    </div>
                    <div className="col pc-aid">
                        <img className="mx-auto d-block" src="../pcaid-noir.svg" alt="PC-AID Logo footer" />
                    </div>
                    <div className="col cgv">
                        {/* <h2>CGV</h2>
                        <p>Lien 1 ...</p>
                        <p>Lien 2 ...</p> */}
                    </div>
                </div>
            </footer>
        );
    }
  }
  
  export default Footer;
